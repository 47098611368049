<script setup>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { $$t } from '@/utils/i19m'
import { updatePlayerLocked, showLoginButton } from '@/helpers/player/index'
import { usePageBuilder } from '~/stores/page-builder'
import { useUrlBuilder } from '~/stores/url-builder'
import { useSignalrBuilder } from '~/stores/signalr-builder'
import { useAuthBuilder } from '~/stores/auth-builder'
import { updateSeekedTime } from '@/helpers/chat/index'

import { SIGNALR_CONNECTION } from '~/constants/signalr/signalrConnections'
import Player from '@/models/player/Player'
import { emitter } from '~/utils/emitter'

const pageBuilderStore = usePageBuilder()
const urlBuilderStore = useUrlBuilder()
const signalrBuilderStore = useSignalrBuilder()
const authBuilderStore = useAuthBuilder()
const playerAccessIds = ref([])

const { query } = useRoute()
const { userToken, isUserLoggedIn, userTonosAccess } =
  storeToRefs(authBuilderStore)

const {
  // videoConfig,
  media,
  // relatedVideos,
  // theaterMode,
  // getLayoutMediaData,
  // playerConfigs,
  pageType,
  getVideoStructuredData,
} = storeToRefs(pageBuilderStore)

const { isCLH } = storeToRefs(urlBuilderStore)

const mediaId = ref(null)
const isLive = ref(false)

const theaterMode = ref(false)
const playerConfigs = ref({
  playerId: null,
  theaterMode: false,
  autoplay: true,
  muted: false,
  playbackQuality: -1,
  volume: 0.5,
  playbackRate: 1,
})

import { formatComponentStyle } from '@/models/layout/style/component'

// const { realTimeMediaViews } = storeToRefs(signalrBuilderStore)
// const { relatedVideos } = storeToRefs(pageBuilderStore);
const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: false,
  },
  hasTheaterMode: {
    type: Boolean,
    default: true,
  },
  isTypeHomepage: {
    type: Boolean,
    default: false,
  },
})

const playerId = `player-element-${props.data?.id || '1'}`
// const layoutMediaData = ref(null)
const isPrivateMedia = ref(false)
const imageUrl = ref(null)
const contentUrl = ref(null)
const isVerticalPlayer = ref(false)
const isPlayerLoaded = ref(false)
const videoLockerRemoved = ref(false)

// const showPlayer = computed(() => {
//   return true
//   if (!props.isTypeHomepage) return true
//   if (!layoutMediaData.value) return false

//   const currentDate = new Date()
//   const startDate = new Date(layoutMediaData.value.startDate)

//   return startDate < currentDate
// })

const style = computed(() => formatComponentStyle(props.data?.style || {}))

const { data: layoutMediaData } = useAsyncData('layoutMediaData', async () => {
  const data = await pageBuilderStore.actionGetMediaData(media.value)
  mediaId.value = data?.mediaId
  isLive.value = data?.isLive
  isVerticalPlayer.value = data?.player?.isVertical
  return data
})

const getFirstFollowingMediaUrl = () => {
  const mediaDataEl = document.querySelector('.vp-component-following-media')

  if (!mediaDataEl) return null

  const mediaDataValue = JSON.parse(mediaDataEl.value)

  const [firstFollowingMedia] = mediaDataValue ?? []
  const firstMediaUrl = firstFollowingMedia?.props?.url

  console.error('mediaDataValue,mediaDataValue', firstMediaUrl)

  return firstMediaUrl
}

const startPlayer = async () => {
  const videoPlayerUrl = layoutMediaData.value?.assets
    ? layoutMediaData.value.assets[0]?.rootUrl
    : null

  const externalVideoId = layoutMediaData.value?.externalMediaId
  const vpPlayerPlayerId =
    layoutMediaData.value?.player?.config?.config?.configId

  const videoLength = layoutMediaData.value?.length
  const videoLockDuration = layoutMediaData.value?.lockedAfter

  const videoPlayerConfig = layoutMediaData.value?.player?.config
    ? layoutMediaData.value.player.config
    : null

  const isLive = layoutMediaData.value?.isLive
  const videoTracks = layoutMediaData.value?.subtitles
  const wachedTime = layoutMediaData.value?.watchTime

  //temp solution for olympics
  if (isLive && vpPlayerPlayerId) {
    // && process.env.NODE_ENV === 'production'

    try {
      const res = await fetch(
        `https://host.vpplayer.tech/player/${vpPlayerPlayerId}/${externalVideoId}.json`
      )
      const data = await res.json()
      const { video } = data
      const { hasGeoRestriction, geoRestrictionLink } = video

      videoPlayerConfig.video.hasGeoRestriction = hasGeoRestriction
      videoPlayerConfig.video.geoRestrictionLink = geoRestrictionLink
    } catch (error) {
      console.error(error)
    }
  }
  if (!isLive && layoutMediaData.value?.hasGeoRestriction) {
    videoPlayerConfig.video.hasGeoRestriction =
      layoutMediaData.value.hasGeoRestriction
    videoPlayerConfig.video.geoRestrictionLink =
      layoutMediaData.value.geoRestrictionLink
  }

  const overlays = layoutMediaData.value?.productPlacements

  if (query?.autoplay) {
    videoPlayerConfig.autoplay = query.autoplay
  }

  try {
    if (
      layoutMediaData.value.imageAssets &&
      layoutMediaData.value.imageAssets.length > 0 &&
      videoPlayerConfig
    ) {
      videoPlayerConfig.video.thumbnail =
        layoutMediaData.value.imageAssets[
          layoutMediaData.value.imageAssets.length - 1
        ].url
    }
  } catch (error) {
    console.error('Move mapping on models')
  }

  const playerModel = await new Player()
  await playerModel.fromData(videoPlayerUrl, videoPlayerConfig, externalVideoId)

  playerModel.setMediaTracks(videoTracks)
  playerModel.setProductPlacements(overlays)

  //for unloggedin users
  const requestLogin = async () => {
    try {
      await authBuilderStore.callLogin()
    } catch (error) {
      console.error('Login click', error)
    }
  }

  playerModel.setVideoLockConfig(videoLockDuration, userToken.value)

  if (playerModel.data.config && playerModel.data.config.lang) {
    // playerModel.data.config.lang.messages.videoLockedTitle = 'Obsah je placený'
    playerModel.setVideoLockMessage($$t('component_player_content_is_paid'))
  }

  if (videoLockDuration && videoLockDuration > 0) {
    playerModel.enableVideoLock()
  }

  playerModel.setTagsOnPlayerConfig()

  if (!videoPlayerUrl) {
    isPrivateMedia.value = true
    return
  }

  try {
    if (typeof vpPlayer === 'undefined') {
      throw 'vpPlayer script is not loaded'
    }

    if (playerModel.data.config) {
      playerModel.data.config.volume = playerConfigs.value.volume
      playerModel.data.config.muted = playerConfigs.value.muted
      playerModel.data.config.autoplay = playerConfigs.value.autoplay
      playerModel.data.config.autostartOnLoad = {
        state: true,
        onMobile: true,
        onData: true,
      }
      playerModel.data.video.live = isLive

      if (playerModel.data.config.lang) {
        playerModel.data.config.lang.locale = 'cz'
      }
    }

    const [progressData] =
      (await pageBuilderStore.postGetWatchTimeByMediaIds([mediaId.value])) || []

    const { watchTime } = progressData ?? {}

    const watchedPercentage = Number.parseInt((watchTime / videoLength) * 100)

    if (watchedPercentage > 95) {
      playerModel.data.video.startTime = 0
    } else if (videoLength > 10 && watchTime && watchTime > 10) {
      playerModel.data.video.startTime = watchTime - 10
      updateSeekedTime(playerModel.data.video.startTime)
    }

    if (query?.t) playerModel.data.video.startTime = Number(query?.t)

    vpPlayer(playerId).setup(playerModel.data)

    const player = vpPlayer(playerId)

    player.on('video-state', (event) =>
      vpanalytics.notifyHandlers('onVideoState', event)
    )

    player.on('vp-event', async (e) => {
      if (e.eventName == 'ready') {
        player.setPlaybackRate(playerConfigs.value.playbackRate)

        removeVideoLocker('ready')
        if (playerConfigs.value.playbackQuality >= 0 && player.hls) {
          const currentHeightIndex = player
            .getQualityLevels()
            .findIndex((x) => x.height === playerConfigs.value.playbackQuality)
          if (!(currentHeightIndex < 0))
            player.setCurrentQuality(currentHeightIndex)
        }
        player.play()
      }

      if (e.eventName == 'videoLocked') {
        updatePlayerLocked(true)
        if (!isLive) {
          showLoginButton(userToken.value ? true : false)
        }
      }
      if (e.eventName == 'vp-request-theater-mode') {
        theaterMode.value = !theaterMode.value
        window.scroll({
          top: 0,
          behavior: 'smooth',
        })
      }

      if (e.eventName == 'mute') playerConfigs.value.muted = e.muted

      if (e.eventName == 'volumeChange')
        playerConfigs.value.volume = parseFloat(player.getVolume().toFixed(2))

      if (e.eventName == 'toggleAutoplay') {
        playerConfigs.value.autoplay = e.state
        localStorage.setItem(
          'playerConfigAutoplay',
          JSON.stringify(playerConfigs.value.autoplay)
        )
      }
      if (e.eventName === 'seeked') {
        updateSeekedTime(e.currentTime.toFixed(0))
      }
      if (e.eventName == 'vp-toggle-quality' && player.hls)
        playerConfigs.value.playbackQuality =
          e.state < 0 ? e.state : player.getQualityLevels()[e.state].height

      if (e.eventName == 'vp-request-change-playback-rate')
        playerConfigs.value.playbackRate = e.rate

      if (e.eventName == 'firstFrame')
        vpanalytics.notifyHandlers('onFirstFramePlay')

      if (e.eventName == 'video-completed')
        vpanalytics.notifyHandlers('onEnded')

      if (e.eventName == 'vp-customView-button-press') {
        requestLogin()
      }
      if (e.eventName == 'customButtonPress') {
        requestLogin()
      }

      if (e.eventName == 'video-started') vpanalytics.notifyHandlers('onPlay')

      if (e.eventName == 'firstQuartile') {
        vpanalytics.notifyHandlers('onFirstQuartilePlay')
      }

      if (e.eventName == 'analytics-25%-completed') {
        vpanalytics.notifyHandlers('onFirstQuartilePlay')
      }

      if (e.eventName == 'secondQuartile')
        vpanalytics.notifyHandlers('onSecondQuartilePlay')

      if (e.eventName == 'analytics-50%-completed') {
        vpanalytics.notifyHandlers('onSecondQuartilePlay')
      }

      if (e.eventName == 'thirdQuartile')
        vpanalytics.notifyHandlers('onThirdQuartilePlay')

      if (e.eventName == 'analytics-75%-completed') {
        vpanalytics.notifyHandlers('onThirdQuartilePlay')
      }

      if (e.eventName == 'video-state')
        vpanalytics.notifyHandlers('videoState', e)

      if (e.eventName == 'vp-video-switch')
        vpanalytics.notifyHandlers('onVideoSwitch', e)

      if (e.eventName == 'watchedtime')
        vpanalytics.notifyHandlers('onWatchedTime', e)

      if (e.eventName == 'video-completed') {
        vpanalytics.notifyHandlers('video-completed', e)
      }

      if (e.eventName == 'complete') {
        const nextVideo = getFirstFollowingMediaUrl()
        // let nextVideo = relatedVideos.value?.data[0]?.url\
        if (playerConfigs.value.autoplay && nextVideo) {
          await navigateTo(`/${nextVideo}`)
        } else {
          player.isAutoplay = false
        }
      }
    })

    isPlayerLoaded.value = true
  } catch (error) {
    console.error(error)
  }
}

const startVerticalPlayer = async () => {
  const videoPlayerUrl = layoutMediaData.value?.assets
    ? layoutMediaData.value.assets[0]?.rootUrl
    : null

  const externalVideoId = layoutMediaData.value?.externalMediaId
  // const videoLength = layoutMediaData.value?.length;
  // const videoLockDuration = layoutMediaData.value?.lockedAfter;

  const videoPlayerConfig = layoutMediaData.value?.player?.config
    ? layoutMediaData.value.player.config
    : null

  // const { isLive } = layoutMediaData.value;
  const videoTracks = layoutMediaData.value?.subtitles
  // const wachedTime = layoutMediaData.value.watchTime;

  if (query?.autoplay) {
    videoPlayerConfig.autoplay = query.autoplay
  }

  const playerModel = await new Player()
  await playerModel.fromData(videoPlayerUrl, videoPlayerConfig, externalVideoId)

  playerModel.setMediaTracks(videoTracks)

  // //for unloggedin users
  // const requestLogin = async () => {
  //   try {
  //     await authBuilderStore.callLogin();
  //   } catch (error) {
  //     console.error("Login click", error);
  //   }
  // };

  // if (!user.value && videoLockDuration && videoLockDuration > 0) {
  //   playerModel.setVideoLockConfig(videoLockDuration, requestLogin);
  // }

  if (!videoPlayerUrl) {
    isPrivateMedia.value = true
    return
  }

  try {
    if (typeof vpPlayer === 'undefined') {
      throw 'vpPlayer script is not loaded'
    }

    // if (playerModel.data.config) {
    //   playerModel.data.config.volume = playerConfigs.value.volume;
    //   playerModel.data.config.muted = playerConfigs.value.muted;
    //   playerModel.data.config.autoplay = playerConfigs.value.autoplay;
    //   playerModel.data.config.autostartOnLoad = {
    //     state: true,
    //     onMobile: true,
    //     onData: true,
    //   };
    //   playerModel.data.video.live = isLive;

    //   if (playerModel.data.config.lang) {
    //     playerModel.data.config.lang.locale = "cz";
    //   }
    // }

    // const watchedPercentage = Number.parseInt((wachedTime / videoLength) * 100);

    // console.error(
    //   "------------------",
    //   wachedTime,
    //   playerModel.data,
    //   videoLength
    // );

    // if (watchedPercentage > 95) {
    //   playerModel.data.video.startTime = 0;
    // } else if (videoLength > 10 && wachedTime && wachedTime > 10) {
    //   playerModel.data.video.startTime = wachedTime - 10;
    // }

    // if (query?.t) playerModel.data.video.startTime = Number(query?.t);
    // let configuration = {
    //   video: {
    //     file: "https://player.vimeo.com/external/481165626.sd.mp4?s=ed9799425cc81068e2e6870ab89cfe0df7e00bd8&profile_id=165&oauth2_token_id=57447761",
    //     title: "Sample video",
    //   },
    // };

    await vpVerticalPlayer('divId').setup(playerModel.data)

    vpVerticalPlayer().on('vp-event', async (e) => {
      // console.error('vertical event',e.eventName, e)

      if (e.eventName == 'mute') playerConfigs.value.muted = e.muted

      // if (e.eventName == "volumeChange")
      //   playerConfigs.value.volume = parseFloat(player.getVolume().toFixed(2))

      if (e.eventName == 'toggleAutoplay') {
        playerConfigs.value.autoplay = e.state
        localStorage.setItem(
          'playerConfigAutoplay',
          JSON.stringify(playerConfigs.value.autoplay)
        )
      }

      if (e.eventName === 'seeked') {
        updateSeekedTime(e.currentTime.toFixed(0))
      }

      if (e.eventName == 'vp-request-change-playback-rate')
        playerConfigs.value.playbackRate = e.rate

      if (e.eventName == 'firstFrame')
        vpanalytics.notifyHandlers('onFirstFramePlay')

      if (e.eventName == 'video-completed')
        vpanalytics.notifyHandlers('onEnded')

      if (e.eventName == 'vp-customView-button-press') {
        requestLogin()
      }

      if (e.eventName == 'video-started') {
        vpanalytics.notifyHandlers('onPlay')
      }

      if (e.eventName == 'firstQuartile')
        vpanalytics.notifyHandlers('onFirstQuartilePlay')

      if (e.eventName == 'secondQuartile')
        vpanalytics.notifyHandlers('onSecondQuartilePlay')

      if (e.eventName == 'thirdQuartile')
        vpanalytics.notifyHandlers('onThirdQuartilePlay')

      if (e.eventName == 'video-state')
        vpanalytics.notifyHandlers('videoState', e)

      if (e.eventName == 'vp-video-switch')
        vpanalytics.notifyHandlers('onVideoSwitch', e)

      if (e.eventName == 'watchedtime')
        vpanalytics.notifyHandlers('onWatchedTime', e)

      if (e.eventName == 'video-completed') {
        const nextVideo = getFirstFollowingMediaUrl()

        // let nextVideo = relatedVideos.value?.data[0]?.url
        if (vpVerticalPlayer().config.autoplay && nextVideo) {
          await navigateTo(`/${nextVideo}`)
        }
      }
    })

    isPlayerLoaded.value = true
  } catch (e) {
    console.error(e)
  }
}

const handleAutoPlayConfig = () => {
  const savedPlayerConfigAutoplay = localStorage.getItem('playerConfigAutoplay')
  if (savedPlayerConfigAutoplay !== null) {
    playerConfigs.value.autoplay = JSON.parse(savedPlayerConfigAutoplay)
  }
}

const init = async () => {
  if (!layoutMediaData.value) {
    layoutMediaData.value = await pageBuilderStore.actionGetMediaData(
      media.value
    )
  }

  // isVerticalPlayer.value = layoutMediaData.value.player?.isVertical

  handleAutoPlayConfig()

  if (
    layoutMediaData.value.imageAssets &&
    layoutMediaData.value.imageAssets.length > 0
  ) {
    imageUrl.value =
      layoutMediaData.value.imageAssets[
        layoutMediaData.value.imageAssets.length - 1
      ].url
  }

  if (layoutMediaData.value.assets && layoutMediaData.value.assets.length > 0) {
    contentUrl.value = layoutMediaData.value.assets[0].rootUrl
  }

  try {
    if (isCLH.value) return

    if (isVerticalPlayer.value) {
      startVerticalPlayer()
    } else {
      await startPlayer()
    }
  } catch (error) {
    console.error(error)
  }

  if (isLive.value && mediaId.value) {
    signalrBuilderStore.setToJoinQueue(
      SIGNALR_CONNECTION.JOIN_REALTIME_VIEWS,
      mediaId.value
    )
  }
}

const onGoBackClick = () => {
  const router = useRouter()
  if (window.history.length > 2) return router.back()

  router.push('/')
}

// useHead({
//   script: [
//     {
//       innerHTML: JSON.stringify(getVideoStructuredData.value),
//       type: "application/ld+json",
//     },
//   ],
// })

onMounted(async () => {
  if (!layoutMediaData.value) {
    console.log('Waiting for layoutMediaData to resolve...')
    await layoutMediaData // This ensures we wait for the data to be fetched
  }

  isVerticalPlayer.value = layoutMediaData.value?.player?.isVertical || false

  // Now run init
  await init()
})

onBeforeUnmount(() => {
  try {
    // console.error('onBeforeUnmount')
    vpPlayer(playerId).destroy()
  } catch (error) {
    console.error(error)
  }
})

const playerSkeletonClasses = computed(() => {
  if (isPlayerLoaded.value) return null

  let skeletonClasses = 'border border-r-2'
  return skeletonClasses
})

onUnmounted(() => {
  if (isLive.value && mediaId.value) {
    signalrBuilderStore.leaveGroup(mediaId.value)
  }
})

onMounted(() => {
  emitter.on('ProductIdAccess', onProductIdAccess)
  emitter.on('userTonosAccess', onTonosAccess)
})

onBeforeUnmount(() => {
  emitter.off('ProductIdAccess', onProductIdAccess)
  emitter.off('userTonosAccess', onTonosAccess)
})

const onTonosAccess = () => {
  removeVideoLocker('access')
}

const removeVideoLocker = (from) => {
  if (videoLockerRemoved.value) return
  if (
    pageType.value === 'media' &&
    playerAccessIds.value.length > 0 &&
    userToken.value !== null
  ) {
    const hasCommonId = playerAccessIds.value.some((id) =>
      userTonosAccess.value.includes(id)
    )

    if (hasCommonId) {
      vpPlayer(playerId)?.removeVideoLock()
      videoLockerRemoved.value = true
    }
  }
}

function onProductIdAccess(newId) {
  playerAccessIds.value.push(newId)
}
</script>

<template>
  <div>
    <!-- <pre class="fixed z-50 bg-red-300 bottom-0 left-0 top-2/3 right-2/3 overflow-scroll">
      {{ mediaId }}
    {{ media }}
  </pre> -->

    <div v-if="data?.props?.hasLabel" class="relative z-10">
      <Label :data="data.components.label" />
    </div>
    <div
      id="apps-player-holder"
      class="relative overflow-hidden"
      :class="[
        playerSkeletonClasses,
        isVerticalPlayer
          ? 'aspect-[21/29] max-w-[500px] mx-auto'
          : 'aspect-video',
        isPrivateMedia ? 'bg-black text-white' : null,
      ]"
      :style="style"
    >
      <div
        v-if="false"
        class="absolute top-2 left-2 text-white z-[10] p-1 rounded-full hover:bg-black/40 cursor-pointer"
        @click="onGoBackClick"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M10.5303 6.53033C10.8231 6.23744 10.8231 5.76256 10.5303 5.46967C10.2374 5.17678 9.76249 5.17678 9.46959 5.46967L3.82315 11.1161C3.33499 11.6043 3.33499 12.3957 3.82315 12.8839L9.46959 18.5303C9.76249 18.8232 10.2374 18.8232 10.5303 18.5303C10.8231 18.2374 10.8231 17.7626 10.5303 17.4697L5.81059 12.75L19.9999 12.75C20.4141 12.75 20.7499 12.4142 20.7499 12C20.7499 11.5858 20.4141 11.25 19.9999 11.25L5.81058 11.25L10.5303 6.53033Z"
            fill="white"
          />
        </svg>
      </div>
      <ClientOnly v-if="isVerticalPlayer">
        <template #fallback>
          <div class="player-element-wrapper">
            <div itemscope itemtype="https://schema.org/VideoObject">
              <meta
                itemprop="uploadDate"
                :content="layoutMediaData?.startDate"
              />
              <meta itemprop="name" />
              <meta itemprop="duration" :content="layoutMediaData?.duration" />
              <meta itemprop="thumbnailUrl" :content="imageUrl" />
              <meta
                v-if="contentUrl"
                itemprop="contentUrl"
                :content="contentUrl"
              />
              <div
                class="lazy-load-player"
                :style="{
                  position: 'relative',
                  overflow: 'hidden',
                  maxWidth: '500px',
                  margin: '0 auto',
                  aspectRatio: '21/29',
                  'background-image': imageUrl ? `url(${imageUrl}?q=30)` : null,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }"
              >
                <video></video>
              </div>
            </div>
          </div>
        </template>
        <Teleport
          to=".kino-mode-player"
          :disabled="!theaterMode || !hasTheaterMode"
        >
          <div class="player-element-wrapper">
            <div itemscope itemtype="https://schema.org/VideoObject">
              <meta
                itemprop="uploadDate"
                :content="layoutMediaData?.startDate"
              />
              <meta itemprop="name" />
              <meta itemprop="duration" :content="layoutMediaData?.duration" />
              <meta itemprop="thumbnailUrl" :content="imageUrl" />
              <meta
                v-if="contentUrl"
                itemprop="contentUrl"
                :content="contentUrl"
              />
              <div
                id="divId"
                :style="{
                  margin: '0 auto',
                  maxWidth: '500px',
                  aspectRatio: '21/29',
                }"
              >
                <video></video>
              </div>
            </div>
          </div>
        </Teleport>
      </ClientOnly>
      <ClientOnly v-else>
        <template #fallback>
          <div class="player-element-wrapper">
            <div itemscope itemtype="https://schema.org/VideoObject">
              <meta
                itemprop="uploadDate"
                :content="layoutMediaData?.startDate"
              />
              <meta itemprop="name" />
              <meta itemprop="duration" :content="layoutMediaData?.duration" />
              <meta itemprop="thumbnailUrl" :content="imageUrl" />
              <meta
                v-if="contentUrl"
                itemprop="contentUrl"
                :content="contentUrl"
              />
              <div
                class="lazy-load-player"
                :style="{
                  position: 'relative',
                  overflow: 'hidden',
                  aspectRatio: '16/9',
                  'background-image': imageUrl ? `url(${imageUrl}?q=30)` : null,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }"
              >
                <video></video>
              </div>
            </div>
          </div>
        </template>
        <Teleport
          to=".kino-mode-player"
          :disabled="!theaterMode || !hasTheaterMode"
        >
          <div class="player-element-wrapper">
            <div itemscope itemtype="https://schema.org/VideoObject">
              <meta
                itemprop="uploadDate"
                :content="layoutMediaData?.startDate"
              />
              <meta itemprop="name" />
              <meta itemprop="duration" :content="layoutMediaData?.duration" />
              <meta itemprop="thumbnailUrl" :content="imageUrl" />
              <meta
                v-if="contentUrl"
                itemprop="contentUrl"
                :content="contentUrl"
              />
              <div :id="playerId">
                <video></video>
              </div>
            </div>
          </div>
        </Teleport>
      </ClientOnly>

      <Head v-if="isPrivateMedia">
        <Title>{{ $$t('component_player_private_media') }}</Title>
      </Head>

      <div
        class="player-is-private absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center"
        v-if="isPrivateMedia"
      >
        {{ $$t('component_player_private_media') }}
      </div>
    </div>
  </div>
</template>

<style>
.kino-mode-player {
  display: block;
}
</style>
